const API_URL = `${process.env.REACT_APP_API_URL}`;
const apiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}`;
export const UrlConstant = {
  common: {
    getWidgetBookingChoiceList: `${API_URL}/v1/Widget/GetWidgetBookingChoiceList`,
    GetBusinessById: (id) => {
      return `${API_URL}/v1/Business/GetBusinessById/${id}`;
    },
    GetFeatureFlags: `${API_URL}/v1/Common/GetFeatureFlags`,
    GetWhiteLabelDetails: `${API_URL}/v1/LicenseApp/GetWhiteLabelDetails`,
    GetParentBusiness: `${API_URL}/v1/Business/GetParentBusiness`,
    getActionItems: `${API_URL}/v1/Dashboard/FetchClientUpfrontActionList`,
  },
  userManagement: {
    getUserTypeList: `${API_URL}/v1/UserManagement/GetUserTypeList`,
    userTypeListForTypeWiseLogin: `${API_URL}/v1/UserManagement/UserTypeListForTypeWiseLogin/false/true`,
    getUserById: `${API_URL}/v1/UserManagement/GetUserById`,
    updateUser: `${API_URL}/v1/UserManagement/UpdateUser`,
  },
  widget: {
    serviceCategoryList: `${API_URL}/v1/Widget/GetServiceCategoriesByBookingType`,
    serviceProviderListForFacility: `${API_URL}/v1/Widget/GetServiceProviderListForFacilityBooking`,
    serviceProviderListForCallout: `${API_URL}/v1/Widget/GetServiceProviderListForCalloutBooking`,
    getServiceListForBookingFlow: `${API_URL}/v1/Widget/GetServiceListForBookingFlow`,
    getServiceProviderProfileDetail: `${API_URL}/v1/Widget/GetServiceProviderProfileDetail/`,
    getReviewList: `${API_URL}/v1/Widget/GetReviewList/`,
    checkUserConsentSignature: `${API_URL}/v1/Widget/CheckUserConsentSignature`,
    getUserConsentDetails: `${API_URL}/v1/Widget/GetUserConsentDetails`,
    getMedicalConsentDetail: `${API_URL}/v1/Widget/GetMedicalConsentDetail`,
    getWidgetFacilities: `${API_URL}/v1/Widget/GetWidgetFacilities`,
    addUserConsentDetails: `${API_URL}/v1/Widget/AddUserConsentDetails`,
    updateUserConsentDetails: `${API_URL}/v1/Widget/UpdateUserConsentDetails`,
    addUserConsentSignature: `${API_URL}/v1/Widget/AddUserConsentSignature`,
    serviceBookingDiscountCalculations: `${API_URL}/v1/Widget/ServiceBookingDiscountCalculations`,
    userMembershipPlanDetailsByBusiness: `${API_URL}/v1/Widget/UserMembershipPlanDetailsByBusiness`,
    userPaymentProfileList: `${API_URL}/v1/PaymentGateway/UserPaymentProfileList`,
    createCustomerPaymentProfile: `${API_URL}/v1/PaymentGateway/CreateCustomerPaymentProfile`,
    addUser: `${API_URL}/v1/Widget/AddUser`,
    getOnboardingUserTypeList: `${API_URL}/v1/OnboardingApp/GetOnboardingUserTypeList`,
    savePreBooking: `${API_URL}/v1/Widget/SavePreBooking`,
    getFacilitiesForFacilityBooking: `${API_URL}/v1/Widget/GetFacilitiesForFacilityBooking`,
    verifyWidgetCodeByEntityType: `${API_URL}/v1/Widget/VerifyWidgetCodeByEntityType`,
    getTelemedicineDescription: `${API_URL}/v1/Widget/GetTelemedicineDescription`,
    getServicesForOnTheSpotAndScheduleBooking: `${API_URL}/v1/Widget/GetServicesForOnTheSpotAndScheduleBooking`,
    getAddonsForOnTheSpotAndScheduleBooking: `${API_URL}/v1/Widget/GetAddonsForOnTheSpotAndScheduleBooking`,
    verifyWidgetMobileMedIvServiceBusinessCode: `${API_URL}/v1/Widget/VerifyWidgetMobileMedIvServiceBusinessCode`,
    getMedicalScreeningDetails: `${API_URL}/v1/MedicalScreening/GetMedicalScreeningConfigurationDetails`,

    getLocationAddressByLatLong: (latitude, longitude) =>
      encodeURI(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=${apiKey}`
      ),
    getUserBookingDetails: `${API_URL}/v1/Widget/GetUserBookingDetails`,
    dummySavePreBookingDetails: `${API_URL}/v1/BookingFlow/DummyServiceBookingFlowCreateBooking`,
    dummyUpdateServiceBooking: `${API_URL}/v1/BookingFlow/UpdateDummyBookingDetails`,
    VerifyBookingTimeAvaibilityWithClubReadyInstructor: `${API_URL}/v1/Widget/VerifyBookingTimeAvaibilityWithClubReadyInstructor`,
    getCountryStateList: `${API_URL}/v1/Common/GetCountryStateDropDownList`,
    checkServiceConsultRequired: `${API_URL}/v1/ServiceConsult/CheckServiceConsultRequired`,
    dummyPatientSpecificSerive: `${API_URL}/v1/PSBookingFlow/DummyPatientSpecificServiceFlowCreateBooking`,
    GetPSFHCPTimeSlots: `${API_URL}/v1/PSBookingFlow/GetPatientSpecificHCPTimeSlots`,
    CalculatePSFProgramPrice: `${API_URL}/v1/BookingFlow/CalculatePSFProgramPrice`,
    BookLabTest: `${API_URL}/v1/BookingFlow/BookLabTest`,
    checkPSServiceAlreadyHasConsultationBooked: `${API_URL}/v1/PSBookingFlow/CheckPSServiceAlreadyHasConsultationBooked`,
    SavePSFMedication: `${API_URL}/v1/PSBookingFlow/SavePSFMedication`,
    PSFMedicationMarkAsDelivered: `${API_URL}/v1/PSBookingFlow/PatientSpecificMedicationMarkAsDelivered`,
    PSFCancelAutoRenewSelfMedication: `${API_URL}/v1/PSBookingFlow/CancelAutoRenewSelfMedication`,
    PSFReorderSelfMedication: `${API_URL}/v1/PSBookingFlow/ReorderSelfMedication`,
    EnableAutoRenewSelfMedication: `${API_URL}/v1/PSBookingFlow/EnableAutoRenewSelfMedication`,
    BookAsyncHCP: `${API_URL}/v1/ServiceProvider/BookAsyncHCP`,
    GetServiceById: `${API_URL}/v1/Service/GetServiceById`,
    GetServiceByIdForAll: `${API_URL}/v1/WidgetManagement/GetServiceByIdForAll`,
    AddSupportRequest: `${API_URL}/v1/SupportRequest/AddSupportRequest`,
  },
  servicePackForm: {
    saveHipaaConsentImageFormDetails: `${API_URL}/v1/ServicePackForm/SaveHipaaConsentImageFormDetails`,
    getHipaaConsentImageFormDetails: `${API_URL}/v1/ServicePackForm/GetHipaaConsentImageFormDetails`,
    getIVTherapyIntakeFormDetails: `${API_URL}/v1/ServicePackForm/GetIVTherapyIntakeFormDetails`,
    saveIVTherapyIntakeFormDetails: `${API_URL}/v1/ServicePackForm/SaveIVTherapyIntakeFormDetails`,
    getFillerMedicaHistoryDetail: `${API_URL}/v1/ServicePackForm/GetFillerMedicalHistoryFormDetails`,
    SaveFillerMedicaHistoryDetail: `${API_URL}/v1/ServicePackForm/SaveFillerMedicalHistoryFormDetails`,
    GetBotoxQuestionnaireForNeurotoxinInjectionFormDetails: `${API_URL}/v1/ServicePackForm/GetBotoxQuestionnaireForNeurotoxinInjectionFormDetails`,
    saveQuestionnaireForNeurotoxinInjectionsFormDetail: `${API_URL}/v1/ServicePackForm/SaveBotoxQuestionnaireForNeurotoxinInjectionFormDetails`,
    getFormSignatureDetails: `${API_URL}/v1/ServicePackForm/GetFormSignatureDetails`,
    saveFormSignatureDetails: `${API_URL}/v1/ServicePackForm/SaveFormSignatureDetails`,
    getNADConsentFormDetails: `${API_URL}/v1/ServicePackForm/GetNADConsentFormDetails`,
    saveNADConsentFormDetails: `${API_URL}/v1/ServicePackForm/SaveNADConsentFormDetails`,
    getIVInfusionConsentFormDetails: `${API_URL}/v1/ServicePackForm/GetIVInfusionConsentFormDetails`,
    saveIVInfusionConsentFormDetails: `${API_URL}/v1/ServicePackForm/SaveIVInfusionConsentFormDetails`,
    saveMicroneedlingConsentFormDetail: `${API_URL}/v1/ServicePackForm/SaveMicroneedlingConsentFormDetails`,
    getMicroneedlingConsentFormDetail: `${API_URL}/v1/ServicePackForm/GetMicroneedlingConsentFormDetails`,
    saveSclerotherapyPolidocanolInjectionInformedConsentFormDetail: `${API_URL}/v1/ServicePackForm/SaveSclerotherapyPolidocanolInjectionInformedConsentFormDetails`,
    getSclerotherapyPolidocanolInjectionInformedConsentFormDetail: `${API_URL}/v1/ServicePackForm/GetSclerotherapyPolidocanolInjectionInformedConsentFormDetails`,
    saveIVBoosterConsentFormDetail: `${API_URL}/v1/ServicePackForm/SaveIVBoosterConsentFormDetails`,
    getIVBoosterConsentFormDetail: `${API_URL}/v1/ServicePackForm/GetIVBoosterConsentFormDetails`,
    getIMInjectionPatientAttestationsFormDetails: `${API_URL}/v1/ServicePackForm/GetIMInjectionPatientAttestationsFormDetails`,
    saveIMInjectionPatientAttestationsFormDetails: `${API_URL}/v1/ServicePackForm/SaveIMInjectionPatientAttestationsFormDetails`,
    getHipaaConsentFormDetails: `${API_URL}/v1/ServicePackForm/GetHipaaConsentFormDetails`,
    saveHipaaConsentFormDetails: `${API_URL}/v1/ServicePackForm/SaveHipaaConsentFormDetails`,
    saveNADIntakeFormDetail: `${API_URL}/v1/ServicePackForm/SaveNADIntakeFormDetails`,
    getNADIntakeFormDetail: `${API_URL}/v1/ServicePackForm/GetNADIntakeFormDetails`,

    //#region Service Pack Form - Carnitine
    saveCarnitineIntakeFormDetails: `${API_URL}/v1/ServicePackForm/SaveCarnitineIntakeFormDetails`,
    getCarnitineIntakeFormDetails: `${API_URL}/v1/ServicePackForm/GetCarnitineIntakeFormDetails`,
    //#endregion

    saveSclerotherapyConsentFormDetail: `${API_URL}/v1/ServicePackForm/SaveSclerotherapyConsentFormDetails`,
    getSclerotherapyConsentFormDetail: `${API_URL}/v1/ServicePackForm/GetSclerotherapyConsentFormDetails`,
    saveBotoxInformedConsentForNeurotoxinFormDetail: `${API_URL}/v1/ServicePackForm/SaveBotoxInformedConsentForNeurotoxinFormDetails`,
    getBotoxInformedConsentForNeurotoxinFormDetail: `${API_URL}/v1/ServicePackForm/GetBotoxInformedConsentForNeurotoxinFormDetails`,
    savePrpConsentFormDetail: `${API_URL}/v1/ServicePackForm/SavePrpConsentFormDetails`,
    getPrpConsentFormDetail: `${API_URL}/v1/ServicePackForm/GetPrpConsentFormDetails`,
    saveIVGoodFaithExamFormDetail: `${API_URL}/v1/ServicePackForm/SaveIVGoodFaithExamFormDetails`,
    getIVGoodFaithExamFormDetail: `${API_URL}/v1/ServicePackForm/GetIVGoodFaithExamFormDetails`,
    saveMesotherapyInformedConsentFormDetail: `${API_URL}/v1/ServicePackForm/SaveMesotherapyInformedConsentFormDetails`,
    getMesotherapyInformedConsentFormDetail: `${API_URL}/v1/ServicePackForm/GetMesotherapyInformedConsentFormDetails`,
    saveBotoxBriefIntakesFormDetail: `${API_URL}/v1/ServicePackForm/SaveBotoxBriefIntakesFormDetails`,
    getBotoxBriefIntakesFormDetail: `${API_URL}/v1/ServicePackForm/GetBotoxBriefIntakesFormDetails`,
    getServicePackFormsForBooking: `${API_URL}/v1/ServicePackForm/GetServicePackFormsForBooking`,
    saveToradolIntakeFormDetail: `${API_URL}/v1/ServicePackForm/SaveTordalIntakeFormDetails`,
    getToradolIntakeFormDetail: `${API_URL}/v1/ServicePackForm/GetToradolIntakeFormDetails`,
    saveZofranIntakeFormDetail: `${API_URL}/v1/ServicePackForm/SaveZofranIntakeFormDetails`,
    getZofranIntakeFormDetail: `${API_URL}/v1/ServicePackForm/GetZofranIntakeFormDetails`,
    saveHipaaAuthorizationsFormDetail: `${API_URL}/v1/ServicePackForm/SaveHipaaAuthorizationsFormDetails`,
    getHipaaAuthorizationsFormDetail: `${API_URL}/v1/ServicePackForm/GetHipaaAuthorizationsFormDetails`,
    getSemaglutideConsentFormDetails: `${API_URL}/v1/ServicePackForm/GetSemaglutideConsentFormDetails`,
    saveSemaglutideConsentFormDetails: `${API_URL}/v1/ServicePackForm/SaveSemaglutideConsentFormDetails`,
    getSemaglutideScreeningToolFormDetails: `${API_URL}/v1/ServicePackForm/GetSemaglutideScreeningToolFormDetails`,
    saveSemaglutideScreeningToolFormDetails: `${API_URL}/v1/ServicePackForm/SaveSemaglutideScreeningToolFormDetails`,
    saveCoQ10ScreeningToolFormDetails: `${API_URL}/v1/ServicePackForm/SaveCoQ10ScreeningToolFormDetails`,
    getCoQ10ScreeningToolFormDetails: `${API_URL}/v1/ServicePackForm/GetCoQ10ScreeningToolFormDetails`,
    getALAConsentFormDetails: `${API_URL}/v1/ServicePackForm/GetALAConsentFormDetails`,
    saveALAConsentFormDetails: `${API_URL}/v1/ServicePackForm/SaveALAConsentFormDetails`,
    getPepcidScreeningToolFormDetails: `${API_URL}/v1/ServicePackForm/GetPepcidScreeningToolFormDetails`,
    savePepcidScreeningToolFormDetails: `${API_URL}/v1/ServicePackForm/SavePepcidScreeningToolFormDetails`,
    GetWeightLossQuestionnaireForPhytFormDetails: `${API_URL}/v1/ServicePackForm/GetWeightLossQuestionnaireForPhytFormDetails`,
    SaveWeightLossQuestionnaireForPhytFormDetails: `${API_URL}/v1/ServicePackForm/SaveWeightLossQuestionnaireForPhytFormDetails`,
    GetHipaaPolicyPhytFormDetails: `${API_URL}/v1/ServicePackForm/GetHipaaPolicyPhytFormDetails`,
    SaveHipaaPolicyPhytFormDetails: `${API_URL}/v1/ServicePackForm/SaveHipaaPolicyPhytFormDetails`,
    GetInformedConsentFormDetails: `${API_URL}/v1/ServicePackForm/GetInformedConsentFormDetails`,
    SaveInformedConsentFormDetails: `${API_URL}/v1/ServicePackForm/SaveInformedConsentFormDetails`,
    GetReturnRefundDetails: `${API_URL}/v1/ServicePackForm/GetReturnRefundDetails`,
    SaveReturnRefundPolicydetails: `${API_URL}/v1/ServicePackForm/SaveReturnRefundPolicydetails`,
  },
  bookingFlow: {
    getServiceProviderList: `${API_URL}/v1/BookingFlow/GetServiceProviderList`,
    getBookingList: `${API_URL}/v1/BookingFlow/GetBookingList`,
    clientRegistration: `${API_URL}/v1/UserManagement/ClientRegistration`,
    getClientDetails: `${API_URL}/v1/BookingFlow/GetClientDetails`,
    assignServiceToClient: `${API_URL}/v1/BookingFlow/AssignServiceToClient`,
    getStateById: `${API_URL}/v1/State/GetStateById`,
    addClientInBooking: `${API_URL}/v1/InSessionBooking/AddClientInBooking`,
    getCountryDropDownList: `${API_URL}/v1/Common/GetCountryDropDownList`,
    getReviewList: `${API_URL}/v1/UserReview/GetReviewList`,
    getServicePreAssessmentQuestions: `${API_URL}/v1/Service/GetServicePreAssessmentQuestions`,
    getServicePreAssessmentResult: `${API_URL}/v1/Service/GetServicePreAssessmentResult`,
    SaveBMIDetails: `${API_URL}/v1/CheckinBooking/SaveBMIDetails`,
    getHCPBookingDetails: (hcpId, consultRequestId) => { return `${API_URL}/v1/BookingFlow/GetHCPBookingDetails/${hcpId}/${consultRequestId}`},
  },
  commissionBalance: {
    getCommissionBalanceList: `${API_URL}/v1/BookingCommission/GetCommissionBalanceList`,
  },
  serviceConsult: {
    getHCPListForServiceConsult: `${API_URL}/v1/ServiceConsult/GetHCPListForServiceConsult`,
    getHCPProfileDetail: `${API_URL}/v1/BookingFlow/GetHCPProfileDetail`,
    getCommissionAmountByServiceHCPWiseInClientServiceConsult: `${API_URL}/v1/ServiceConsult/GetCommissionAmountByServiceHCPWiseInClientServiceConsult`,
    getConsultationList: `${API_URL}/v1/ServiceConsult/GetConsultationList`,
    getUserBookingServiceConsultList: `${API_URL}/v1/ServiceConsult/GetUserBookingServiceConsultList`,
    getServiceConsultDetail: `${API_URL}/v1/WidgetManagement/GetServiceConsultDetail`,
    getServiceConsultByServiceProviderDetail: `${API_URL}/v1/ServiceConsult/GetServiceConsultByServiceProviderDetail`,
    updateServiceConsult: `${API_URL}/v1/ServiceConsult/UpdateServiceConsult`,
    saveServiceConsult: `${API_URL}/v1/ServiceConsult/SaveServiceConsult`,
    serviceConsultBookingResponse: `${API_URL}/v1/ServiceConsult/ServiceConsultBookingResponse`,
    cancelServiceConsult: `${API_URL}/v1/ServiceConsult/CancelServiceConsult`,
    serviceConsultationReminderByEventType: `${API_URL}/v1/Scheduler/ServiceConsultationReminderByEventType`,
    deleteServiceConsult: `${API_URL}/v1/ServiceConsult/DeleteServiceConsult`,
    hcpAcceptRejectConsultation: `${API_URL}/v1/ServiceConsult/HcpAcceptRejectConsultation`
  },
  BookingCancel: {
    cancelBookingByClient: `${API_URL}/v1/BookingCancel/CancelBookingByClient`,
  },
  media: {
    getMediaList: `${API_URL}/v1/Media/GetMediaList`,
    deleteMedia: `${API_URL}/v1/Media/DeleteMedia`,
    uploadMedia: `${API_URL}/v1/Media/UploadMedia`,
    uploadMultipleMedia: `${API_URL}/v1/Media/UploadMultipleMedia`,
  },
  Twilio: {
    generateTwilioToken: `${API_URL}/v1/Twilio/GenerateTwilioToken`,
    disconnectRoomForAllParticipants: `${API_URL}/v1/Twilio/DisconnectRoomForAllParticipants`,
  },
  payout: {
    saveRequestPayout: `${API_URL}/v1/CommissionPayoutTransaction/SaveRequestPayout`,
  },
  affiliate: {
    getAffiliateCommissionListByType: `${API_URL}/v1/Affiliate/GetAffiliateCommissionListByType`,
  }
};
