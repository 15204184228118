import React, { useEffect, useState } from "react";
import { handleFetchGet } from "../../../utils/widgetApiCallsProvider";
import { DataConstant, UrlConstant } from "../../constants";
import { returnToken, findMessage } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "./_redux/widgetRedux";
import { actionTypes as consultationActionTypes } from "./_redux/consultationRedux";
import { appActionTypes } from "./_redux/appRedux";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const WidgetVerifyBusiness = ({ history,
  match: {
    params: { code },
  } }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatchClearBookingDetail();
    dispatchClearConsultationDetail();
    dispatchClearMobileMedIvFlow();
    verifyWidgetCodeByEntityType();
    dispatchWidgetPopup();
  }, []);

  const dispatchClearBookingDetail = () => {
    dispatch({
      type: actionTypes.ClearBookingDetail,
    });
  };

  const dispatchClearConsultationDetail = () => {
    dispatch({
      type: consultationActionTypes.setClearConsultationDetail,
    });
  };

  const dispatchClearMobileMedIvFlow = () => {
    dispatch({
      type: actionTypes.ClearMobileMedIvFlow,
    });
  };

  const dispatchWidgetPopup = () => {
    let widgetPopup = false;
    if (window.location !== window.parent.location) {
      widgetPopup = true
    }
    dispatch({
      type: actionTypes.SetWidgetPopup,
      payload: {
        isWidgetPopup: widgetPopup,
      },
    });
  };
  const verifyWidgetCodeByEntityType = () => {
    var verifyWidgetCodeByEntityType = `${UrlConstant.widget.verifyWidgetCodeByEntityType}/${code}`
    handleFetchGet(
      verifyWidgetCodeByEntityType
    ).then((response) => {
      if (
        response &&
        response.status?.toLowerCase() ===
        DataConstant.apiResponseStatus.success.toLowerCase() &&
        response.data
      ) {
        dispatch({
          type: actionTypes.SetWidgetToken,
          payload: {
            widgetToken: response?.data?.token,
            widgetUrl: window.location.href,
            widgetCode: code,
            googleAnalyticsId: response?.data?.googleAnalyticsId
          },
        });
        dispatch({
          type: actionTypes.UpdateBusinessDetail,
          payload: {
            businessId: response?.data?.businessId,
          },
        });
        history.push(`${returnToken()}`)
      } else {
        if (response &&
          response.status?.toLowerCase() ===
          DataConstant.apiResponseStatus.WARNING.toLowerCase()) {
          setErrorMessage(findMessage(response.message));
          NotificationManager.error(findMessage(response.message));
        }
        else if (response &&
          response.status?.toLowerCase() ===
          DataConstant.apiResponseStatus.NOTFOUND.toLowerCase()) {
          history.push('/widget/404')
        }
      }
    });
  };

  return (
    <>
      {
        errorMessage &&
        <div className="container cm-px-25">
          <div className="row justify-content-center flex-md-row flex-column flex-md-wrap flex-nowrap pt-10 cm-content-section">
            <p className="text-danger text-center font-size-h1 font-weight-bold text-justify">
              {errorMessage}
            </p>
          </div>
        </div>
      }
    </>
  );
};

export default WidgetVerifyBusiness;

export const useVerifyBusiness = (code) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const appDetail = useSelector((state) => state.app);

  const clearAllState = () => {
    let widgetPopup = false;
    if (window.location !== window.parent.location) {
      widgetPopup = true
    }
    dispatch([
      {
        type: actionTypes.ClearWidgetDetails,
      },
    ]);
  }

  const verifyWidgetCode = () => {
    clearAllState();
    var verifyWidgetCodeByEntityType = `${UrlConstant.widget.verifyWidgetCodeByEntityType}/${code}`
    handleFetchGet(
      verifyWidgetCodeByEntityType
    ).then((response) => {
      if (
        response &&
        response.status?.toLowerCase() ===
        DataConstant.apiResponseStatus.success.toLowerCase() &&
        response.data
      ) {
        dispatch([
          {
            type: actionTypes.SetWidgetToken,
            payload: {
              widgetToken: response?.data?.token,
              widgetUrl: window.location.href,
              widgetCode: code,
              googleAnalyticsId: response?.data?.googleAnalyticsId
            },
          },
          {
            type: actionTypes.UpdateBusinessDetail,
            payload: {
              businessId: response?.data?.businessId,
            },
          }
        ]);
        const isCurrentBusinessIsChildOfParent = appDetail.childBusinessCodeList?.includes(code);
        if (!isCurrentBusinessIsChildOfParent) {
          dispatch({
            type: appActionTypes.ClearParentAffiliateChildBusinessDetails,
          });
        }
      } else {
        if (response &&
          response.status?.toLowerCase() ===
          DataConstant.apiResponseStatus.WARNING.toLowerCase()) {
          setErrorMessage(findMessage(response.message));
          NotificationManager.error(findMessage(response.message));
        }
        else if (response &&
          response.status?.toLowerCase() ===
          DataConstant.apiResponseStatus.NOTFOUND.toLowerCase()) {
          history.push('/widget/404')
        }
      }
    });
  };

  return verifyWidgetCode;
};
